import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6FG5HVHPRK"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  return (
    <>
      <Helmet>
          <title>Digitech Africa Ltd </title>
          <meta name="description" content="Digitech Africa Ltd African Leader for Insurtech Cloud Solutions in Inusurance and Reinsurance" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Landing />
    </>
  );
}

